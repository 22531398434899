.trading-point-data {
    .tabs {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        align-content: center;
        gap: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--light-white-alpha-10);

        .tab {
            color: var(--light-white);
            font-size: 18px;
            opacity: 0.8;
            padding: 10px;
            cursor: pointer;

            &.tab-active {
                color: var(--white);
                border-bottom: 2px solid var(--green-500);
                opacity: 1;
            }
        }   
    }
}

@media (max-width: 500px) {
    .trading-point-data {
        .tabs {
            width: 100%;
            overflow-x: auto;
        }
        .content {
            min-height: 90vh;
        }
    }
}