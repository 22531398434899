.kanban-container {
  display: flex;
  max-width: 100%;
  overflow-x: scroll;

  .kanban-stage {
    color: white;
    margin: 8px;
    min-width: 250px;
    min-height: 500px;
    width: 250px;
    font-family: 'Oswald';
    border: 2px solid var(--dark-gray);
    border-radius: 10px;
    padding: 10px;
    padding-top: 0;
    box-shadow: 0 0 10px 0 #00AA52;
    background-color: #222;
    h3 {
      color: var(--green-500);
    }

    .droppable-zone {
      min-height: 200px;
    }
  }
  .kanban-dragger {
    userSelect: none;
    padding: 16px;
    margin: 0 0 8px 0;
    backgroundColor: darkgray;
    border: 1px solid var(--light-white-alpha-40);
    border-radius: 10px;
    background: var(--black);
  }
}

.mode-chooser {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 0;
  color: var(--white);
  .mode-chooser-item {
    display: flex;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    i {
      opacity: 0.5;
      margin-right: 10px;
    }

    border-bottom: 2px solid transparent;
    &.mode-chooser-item-active {
      border-color: var(--green-500);
    }
  }
}

@import "kanban-xs";