@media (max-width: 500px) {
  .kanban-container {
    display: flex;
    max-width: 100%;
    overflow-x: scroll;

    .kanban-stage {
      color: white;
      margin: 6px;
      min-width: 150px;
      min-height: 200px;
      font-family: 'Oswald';
      border: 1px solid var(--dark-gray);
      border-radius: 6px;
      padding: 10px;
      padding-top: 0;
      box-shadow: 0 0 4px 0 #00AA52;
      background-color: #222;
      padding-top: 10px;
      h3 {
        color: var(--green-500);
        font-size: 18px;
        margin-bottom: 25px;
      }

      .droppable-zone {
        min-height: 200px;
      }
    }
    .kanban-dragger {
      userSelect: none;
      padding: 6px;
      font-size: 14px;
      margin: 0 0 8px 0;
      backgroundColor: darkgray;
      border: 1px solid var(--light-white-alpha-40);
      border-radius: 10px;
      background: var(--black);
    }
  }
}