header {
  margin-top: 0;
  padding-top: 0;
  .xs-top {
    display: none;
  }

  .news {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }
}

@import "header-xs";