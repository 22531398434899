@media (max-width: 500px) {
  .documents-page {
    padding: 10px;
    width: 100%;
    .documents {
      .document-item {
        width: 100%;
        .document-item-header {
          h3 {
            font-size: 15px;
            width: 40%;
          }
        }
        .document-item-content {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }
}