.search {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 40vw;
  margin-right: auto;
  margin-left: auto;

  .search-container {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    width: 40vw;
    position: relative;
    margin-left: 10px;
    margin-right: 20px;

    i.fa-search {
      position: absolute;
      left: 20px;
      color: var(--white);
      opacity: 0.3;
      z-index: 1;
    }

    input {
      padding-left: 50px;
      width: 70%;
      margin: 0;
      background: linear-gradient(90deg, var(--black) 0%, var(--dark-black) 200%);
      color: var(--green-500);
      backdrop-filter: blur(6px);
    }

    button {
      i {
        font-size: 16px;
        margin-right: 10px;
        color: var(--green-500);
        //color: var(--green-500);
      }
      font-size: 16px;
      background: transparent;
      color: var(--light-white);
      font-weight: 300;
    }
  }
}

@import "searches-xs";