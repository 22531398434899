.circular-dial {
    position: relative;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;

    @media (max-width: 1600px) {
        width: 180px;
    }

    @media (max-width: 768px) {
        width: 100%;
    }

    .circular-dial__value {
        font-size: 24px;
    }

    .circular-dial__max-value {
        font-size: 14px;
    }

    svg {
        padding: 0;
    }

    text {
      font-family: "Oswald", sans-serif;
    }

}