@media (max-width: 768px) {
  .trading-points-list-container {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    .trading-point-chooser {
      width: 100%;
      min-width: unset;
      max-width: unset;
      margin-bottom: 30px;
      .trading-points-list {
        margin-bottom: 10px;
        flex-direction: row;
        overflow-x: scroll;
        .trading-point-button {
          padding: 2px;
          color: var(--dark-white);
          cursor: pointer;
          .metrics {
            display: flex;
            flex-direction: column;
            padding-bottom: 0;
            height: 100%;

            h5 {
              font-size: 0.8rem;
              min-width: 90px;
              margin-bottom: 5px;
            }

            p.target-plan {
              min-width: unset;
              width: unset;
              i {
                font-size: 15px;
              }
              margin: 0;
              margin-top: auto;
              font-size: 15px;
            }
          }

          &:hover {
            background-color: var(--green-500);
          }
        }
      }
    }

  }
}