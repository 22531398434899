.trading-points-stats {
  .search-zone {
    background: transparent;
    border: none;
    width: calc(50% - 18px);
    margin-bottom: 12px;
    input {
      width: calc(100%);
    }
  }

  .main-metric-widget:last-child {
    margin-right: auto;
    margin-left: 30px;
  }
}
@media (max-width: 1600px) {
  .trading-points-stats {
    .main-metric-widget:last-child {
      margin-right: unset;
      margin-left: 10px;
    }
  }
}

@media (max-width: 1024px) {
  .trading-points-stats {
    .main-metric-widget:last-child {
      margin-right: unset;
      margin-left: 5px;
    }
  }
}