@media (max-width: 500px) {
  .menu-button {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    cursor: pointer;
    
    

    .title {
    }

    .amount {
    }

    .title-skeleton {
      @extend .title;
      height: 30px;
      @include skeleton;
    }
    .amount-skeleton {
      @extend .amount;
      @include skeleton;
    }
  }

}
