@media (max-width: 1200px) {
    .inspections {
        padding: 0 15px;
        
        h1 {
            font-size: 20px;
            margin: 15px 0;
        }
    
        .inspection-item {
            width: 100%;
            flex-direction: column;
            padding: 5px;
            margin-bottom: 20px;
    
            &__info {
                width: 100%;
            }
    
            &__dates {
                margin-top: 15px;
                margin-bottom: 10px;
                gap: 10px;
                font-size: 10px;
    
                i {
                    font-size: 14px;
                }
    
                &__started-at,
                &__finished-at {
                    font-size: 10px;
                    width: 100%;
                }
            }
    
            &__pattern {
                font-size: 16px;
                margin-bottom: 5px;
                margin-top: 0px;
            }
            
            &__company {
                font-size: 12px;
                margin-top: 5px;
                opacity: 0.5;
            }
    
            &__rate {
                font-size: 16px;
                margin-top: 10px;
            }
    
            &__actions {
                margin-top: 15px;
                justify-content: flex-start;
                gap: 15px;
            }
    
            &__action {
                font-size: 16px;
            }
        }
    }
}