.full-metric-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 8px;

    .date-interval {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        button {
            margin-right: 10px;     
            height: 100%;
            text-transform: uppercase;
        }  

        input {
            height: 100%;
            margin: 0;
            margin-left: 10px;
        }   
    }

    .active {
        background-color: var(--green-500);
        color: var(--white);
    }
}

@import "full-metric-widget-xs";