@media (max-width: 500px) {

    .trading-points-page-content {
        padding: 6px;
        width: 100%;
        height: 100%;

        h1 {
            font-family: 'Oswald', sans-serif;
            font-size: 1.4rem;
            font-weight: 600;
            margin-bottom: 16px;
            color: var(--white);
        }


    }

}