.news-article {
  border-radius: 10px;
  //background: var(--light-white);
  padding: 0px;
  min-width: 220px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  background: var(--blue-gray);
  //border: 2px solid var(--green-500);
  color: var(--white);
  width: 50vw;
  overflow-y: scroll;
  max-height: 80vh;
  overflow-x: hidden;

  h1 {
    font-family: "Oswald";
    margin: 0;
    margin-bottom: 0px;
    color: var(--green-500);
    font-size: 24px;
  }
  h2 {
    margin: 0;
    font-size: 20px;
    color: var(--darker-gray);
    margin-bottom: 5px;
  }

  .news-item {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 16px;
    }
    .header-zone {
      display: flex;
      flex-direction: row;
      .news-item-header {
        width: 100%;

        .title-zone {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          h3 {
            width: 90%;
            line-height: 1.1em;
          }
          .likes {
            font-size: 12px;
            color: var(--gray);
            opacity: 0.8;
            font-weight: 300;
            cursor: pointer;
            display: flex;
            align-content: center;
            align-items: center;
            i {
              cursor: pointer;
            }

            &:hover {
              color: var(--green-500);
            }
          }
        }

        .time {
          margin-top: 4px;
          margin-bottom: 4px;
          font-size: 10px;
          opacity: 0.3;
          color: var(--gray);
        }
        display: flex;
        flex-direction: column;
        padding: 0;
        h3 {
          font-family: 'Oswald', sans-serif;
          margin: 0;
          font-size: 1.4rem;
          font-weight: 600;
          margin-bottom: 0px;
          color: var(--green-500);
        }

        p.lead {
          margin: 0;
          font-size: 12px;
          line-height: 1.6;
          color: var(--gray);
          opacity: 0.8;
          font-family: "Oswald", sans-serif;
          margin-bottom: 9px;
        }

        span {
          font-family: 'Oswald', sans-serif;
          color: var(--green-500);
          font-size: 1rem;
          font-weight: 500;
          border: none;
          background: none;
          cursor: pointer;
        }
      }
    }

    .keywords {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      .keyword {
        border-radius: 5px;
        color: var(--green-500);
        font-size: 0.7rem;
        border: 1px solid var(--green-500);
        padding: 7px;
        padding-bottom: 5px;
        font-weight: 600;
        margin-right: 9px;
      }
    }

    background: var(--dark-gray);
    border-radius: 8px;
    padding: 10px;
    padding-bottom: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    .news-item-body {
      margin-top: 10px;
      margin-bottom: 0px;
      font-size: 18px;
      //background: var(--black);
      //padding: 10px;
      border-radius: 8px;

      a {
        color: var(--green-500);
      }
    }

  }
}

@import "news-article-xs";