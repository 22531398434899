.kanbaned-tickets {
    color: var(--white);

    .kanbaned-ticket {
        position: relative;
        display: flex;
        flex-direction: column;
        
        margin: 10px 0;
        background-color: var(--black);
        border-radius: 10px;
        padding: 10px;

        .kanbaned-ticket-title {
            font-size: 18px;
            font-weight: 300;
            font-family: "Oswald", sans-serif;
            letter-spacing: 1px;
        }

        .kanbaned-ticket-status {
            margin: 0;
            font-size: 12px;    
            color: var(--gray);
            margin-bottom: 15px;

            .kanbaned-ticket-status-label {
                font-weight: 300;
            }
        }

        .process-line {
            height: 8px;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 10px;
            background-color: var(--dark-gray);
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            .process-line-item {
                width: 0%;
                height: 6px;
                // background: linear-gradient(4deg, var(--green-500) 0%, var(--gray) 100%);
                background: linear-gradient(4deg, var(--green-500) 0%, #0f0 100%);
                border-radius: 10px;
            }
        }
    }
    
}