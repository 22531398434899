@import "body/body";
@import "positioning/positioning";
.application {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100vh;
  min-height: 99vh;
  overflow: hidden;
  padding-bottom: env(safe-area-inset-bottom); // Add padding for Safari bottom bar/home indicator
  -webkit-box-sizing: border-box; // Ensure padding is included in height calculation
  box-sizing: border-box;
}

@media (max-width: 500px) {
  .application {
    position: fixed;
  }
}