*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: var(--dark-gray); /* or add it to the track */
  opacity: 0.2;
}

/* Add a thumb */
*::-webkit-scrollbar-thumb {
  width: 20px;
  border-radius: 9px;
  background: var(--green-500);
}