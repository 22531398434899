.documents-page {
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: var(--white);

    .documents {
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;

        .document-item {
            position: relative;
            display: flex;
            border-radius: 10px;
            padding: 0px;
            width: 30%;
            margin: 10px;
            background: var(--blue-gray);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
            flex-direction: row;
            align-items: center;
            align-content: center;
            height: 120px;
            background: linear-gradient(-135deg, var(--green-500) 15%, var(--black) 4%, var(--gray-900) 80%,);

            .date {
                opacity: 0.4;
                width: 100%;
                font-size: 14px;
                margin: 0;
                margin-top: 90px;
                text-align: left;
                position: absolute;
                left: 5px;
            }

            .doc-number {
                display: flex;
                position: absolute;
                bottom: -10px;
                right: 5px;
                opacity: 0.3;
            }

            h3.document-type {
                opacity: 0.8;
                position: absolute;
                top: 5px;
                left: 5px;
                width: 50%;
                font-size: 20px;
                text-shadow: 0 0 1px var(--blue-gray);
                text-transform: uppercase;
                color: var(--white);
            }

            h5.company-name {
                font-size: 12px;
                opacity: 0.5;
                position: absolute;
                bottom: 5px;
                line-height: 12px;
                right: 6px;
                font-weight: 300;
                text-align: right;
                width: 50%;
            }

            .document-item-content {
                position: absolute;
                top: 10px;
                right: 10px;
                opacity: 0.7;
                margin-left: auto;
                display: flex;
                align-items: center;
                align-content: center;
                padding: 0;
                p {
                    margin: 0;
                    font-size: 25px;
                    margin-right: 15px;
                }
                i {
                    font-size: 19px;
                    opacity: 0.9;
                }
            }

            .document-item-header {
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                h2 {
                    margin: 0;
                }

                h3 {
                    margin: 0;
                }

                p {
                    margin: 0;
                }
            }



            h2 {
                font-size: 24px;
            }

            h3 {
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@import "documents-md";
@import "documents-xs";