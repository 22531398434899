@import "src/styles/animations/skeleton/mixins/skeleton_mixin";

.chat-opening-button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    cursor: pointer;
    max-width: 100px;

    .tooltip {
        position: absolute;
        top: 0;
        left: -50%;
        width: 100%;
        height: 100%;
        background-color: var(--dark-gray);
        color: var(--white);
        opacity: 0;
        pointer-events: none;
    }

    .chat-opening-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: var(--green-500);
        color: var(--white);
        font-size: 14px;
        margin-bottom: 4px;
        cursor: pointer;
        transition: all 0.3s ease;


        .amount {
            position: absolute;
            top: 0px;
            left: 70%;
            padding: 0px;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            justify-content: center;
            align-items: center;
            align-content: center;
            background-color: var(--red-500);
            color: var(--white);
            font-size: 14px;
            margin: 0;
            text-align: center;
        }
    }
    .title {
        margin-left: 0px;
        color: var(--light-gray);
        font-size: 12px;
        font-weight: 300;
        text-align: left;
        transition: all 0.3s ease;
        max-width: 100px;
        width: 100%;
    }

    &:hover {
        filter: brightness(1.1);

        .title {
            color: var(--white);
            font-weight: 600;
            font-size: 12px;
        }

        .tooltip {
        }
    }

    .chat-opening-button-skeleton {
        @extend .chat-opening-button;
        @include skeleton;
    }

    .chat-opening-button-title-skeleton {
        display: flex;
        width: 80px;
        height: 16px;
        @include skeleton;
    }
}

@import "./chat-opening-button-xs.scss";