@media (max-width: 500px) {
    .employees-page {
        color: var(--white);
        h1 {
            margin-bottom: 20px;
        }

        .employees-page-content {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 20px;
            height: unset;
            min-height: unset;

            .companies-list {
                display: flex;
                flex-direction: row;
                width: 100%;
                height: 90px;
                border-radius: 10px;
                padding: 0px;
                background-color: transparent;
                overflow-x: auto;
                overflow-y: scroll;
                background: var(--black);
                padding-bottom: 0;
                p {
                    padding: 10px;
                    font-size: 12px;
                    margin: 0;
                }
            }   
    
        .employees-list {    
            padding: 0 10px;
            h2 {
                color: var(--green-500);
                margin: 0;
                margin-bottom: 10px;
            }
            display: flex;
            flex-direction: column;
            gap: 20px;
            color: var(--white);
    
            .employee-card {
                background-color: var(--green-500);
                padding: 10px;
                color: var(--white);
                opacity: 0.8;
                }   
            }   
        }
    }
}