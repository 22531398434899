@media (max-width: 500px) {
  aside {
    position: fixed;
    top: 0;
    gap: 15px;
    min-width: 250px;
    z-index: 20;
    transform: translateX(-480px);

    .user-data-container {
      padding-top: 15px;
      padding-left: 10px;
    }
  }
}