@media (max-width: 1600px) {
  .employees-page {
    color: var(--white);
    h1 {
      margin-bottom: 20px;
    }

    .employees-page-content {
      display: flex;
      width: 100%;
      gap: 20px;

      .companies-list {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        gap: 0px;
        width: 35%;
        padding: 0px;
        height: 50vh;
        background-color: var(--blue-gray);
        overflow: scroll;

        p {
          font-family: "Oswald", sans-serif;
          padding: 10px;
          margin: 0;
          height: 80px;
          display: flex;
          flex-direction: column;
          align-items: center;
          align-content: center;
          justify-content: center;
          font-size: 19px;
          border-bottom: 1px solid rgba(200, 200, 200, 0.2);

          &:hover {
            //background-color: var(--green-500-alpha14);
            background: #f00;
          }
        }
      }

      .employees-list {
        h2 {
          color: var(--green-500);
          margin: 0;
          margin-bottom: 10px;
          font-family: "Oswald", sans-serif;
        }
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: var(--white);

        .employee-card {
          position: relative;
          width: 80%;
          padding: 10px;
          border-radius: 10px;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          min-width: 300px;
          background: linear-gradient(45deg, var(--green-500), var(--black));

          .fa-user-circle {
            position: absolute;
            top: 5px;
            right: 5px;
            opacity: 0.4;
            color: var(--green-500);
          }

          .employee-card-data {
            width: 90%;
            h3 {
              opacity: 0.9;
              color:  var(--white);
              margin: 0;
              margin-bottom: 15px;
              font-size: 22px;
              font-family: "Oswald", sans-serif;
            }
            h4 {
              font-weight: 300;
              margin: 0;
              margin-top: auto;
              font-size: 14px;
              opacity: 0.8;
            }
          }
        }
      }
    }


  }
}