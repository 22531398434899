.status-column {
  margin: 0;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  max-width: 250px;
  color: var(--white);
  font-size: 13px;
  padding: 5px;
  padding-top: 0;
  border-radius: 5px;
  background: transparent;
  user-select: none;
  height: 100vh;

  .show-calendar-button {
    display: none;
    color: var(--green-500);
    background: var(--black);
  }

  .rdp-root {
    --rdp-accent-color: var(--white);
    --rdp-background-color: var(--gray-900);
    --rdp-text-color: var(--white);
    --rdp-outside-opacity: 0.2;
  }
  .calendar-container {
    //background: var(--gray-900);
    display: flex;
    flex-direction: column;
    max-width: 250px;
    color: var(--primary-200);
    font-size: 13px;
    padding: 5px;
    padding-top: 0;
    border-radius: 5px;

    .rdp-caption_label {
      font-family: 'Montserrat', sans-serif;
      text-transform: capitalize;
      color: var(--green-500);
    }

    .rdp-day_button {
      width: 30px;
      height: 30px;
    }
  }
}

@media (max-width: 1100px) {
  .status-column {
    border-left: 1px solid var(--blue-gray);
    padding-top: 50px;
    max-width: 70px;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, var(--dark-black), var(--blue-gray));
    .show-calendar-button {
      display: flex;
      margin-bottom: 10px;
    }

    .calendar-container {
      display: none;
    }
  }
}