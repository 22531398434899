@media (max-width: 500px) {
  .news {
    padding-top: 20px;
    padding-left: 8px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    max-width: 99%;
    overflow-x: scroll;
    overflow-y: hidden;
    margin-right: auto;
    z-index: 0;
  }
}
