@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,100..700;1,100..700&family=Oswald:wght@100..700&display=swap');

@media (max-width: 500px) {

  .main-metric-widget {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    min-width: 120px;
    width: 100%;
    max-width: 330px;
    min-width: 230px;
    margin: 6px;

    &:first-child {
      margin-left: 10px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      position: absolute;
      background: rgba(0, 0, 0, 0.55);
      backdrop-filter: blur(1px);
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      padding-top: 7px;
      .value {
        margin: 0px;
        color: var(--light-gray);
        font-family: 'Oswald';
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .caption {
        margin: 0px;
        opacity: 0.7;
        color: var(--light-gray);
        font-family: 'Arimo';
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      .prev-delta {
        margin: 10px;
        margin-top: 10px;
        color: var(--green-500);
        font-weight: bold;
        font-family: 'Oswald';
      }
    }
  }
}
