.chat-vertical-bar {
  margin-top: 0px;
  margin-left: 2px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow-x: hidden;

  input.filter {
    background: transparent;
    color: var(--green-500);
    border: 1px solid var(--green-500);
  }

  &__item {
    min-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    span.title {
      text-align: center;
    }
  }


  .birthdays-part {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: visible;
    overflow-x: scroll;
    width: 100%;

    .birthday-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        margin: 5px;
        margin-left: 0px;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 10px;
        font-size: 10px;
        color: var(--green-500);
        border: 1px solid var(--green-500);
    }
  }

  .chats-part {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: unset;
    justify-content: space-around;
    overflow-y: scroll;
    max-height: 60vh;
    overflow-x: hidden;
    width: 100%;
  }

  h3 {
    opacity: 0.8;
    width: 100%;
    font-weight: bold;
    font-family: "Oswald", sans-serif;
  }

  .chat-closing-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
    width: 100%;
    cursor: pointer;
    background: transparent;
    border: none;

    i {
        color: var(--white);
        font-size: 1.2rem;
        margin-right: 10px;
    }
    p {
      color: var(--white);
      font-size: 1.2rem;
      font-weight: 600;
    }
  }
}

.chat-vertical-bar-with-active-chat {
  @extend .chat-vertical-bar;
}

.chat-vertical-bar::-webkit-scrollbar {
  display: none;
}

@import "./chat-vertical-bar-xs.scss";