.trading-point-chooser {
  padding: 0;
  padding-left: 15px;
  padding-bottom: 10px;
  border-radius: 8px;
  //background-color: var(--blue-gray);
  background-color: var(--dark-gray);
  margin-right: 9px;
  max-width: 24%;
  min-width: 24%;
  max-height: 59vh;
  overflow-y: scroll;

  .search-bar {

    input {
      width: calc(100% - 20px);
      height: 30px;
      border-radius: 8px;
      border: none;
      padding: 20px 20px;
      margin-top: 10px;
      background-color: transparent;
      border: 1px solid var(--light-blue-gray);
      color: var(--gray-500);
      font-size: 1.2rem;

      &::placeholder {
        color: var(--gray-500);
      }
    }

  }

  .trading-points-list {

    display: flex;
    flex-direction: column;
    margin-right: 20px;

    .trading-point-button {
      padding: 10px 10px;
      color: var(--dark-white);
      cursor: pointer;

      .metrics {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        border-bottom: 1px solid var(--light-blue-gray);
        padding-bottom: 10px;

        h5 {
          font-family: 'Oswald', sans-serif;
          font-weight: 600;
          line-height: 1.01em;
          font-size: 1.2rem;
          margin: 0;
          margin-right: 5px;
        }

        p.target-plan {
          min-width: 120px;
          text-align: center;
          color: var(--green-500);
          font-family: 'Oswald', sans-serif;
          font-size: 20px;
          background-color: rgba(0,0,0,0.5);
          padding: 6px;
          border-radius: 9px;
          margin: 2px;
          margin-left: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: space-between;
          i {
            font-size: 14px;
            opacity: 0.8;
            margin: 0;
            margin-right: 6px;
          }
        }
      }

      &:hover {
        background-color: var(--green-500);
      }
    }

    .trading-point-active {
      @extend .trading-point-button;
      background-color: var(--green-500);

      .metrics {
        p.target-plan {
          background-color: rgba(0, 0, 0, 0.8);
        }
      }
    }
  }
}

@import "trading-points-chooser-md";
@import "trading-points-chooser-sm";