.main-metrics {
  width: 100%;
  max-width: 100vw;
  overflow-y: visible;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
  z-index: 10;
}

@media (max-width: 1600px) {
  .main-metrics {
  }
}

@media (max-width: 500px) {
  .main-metrics {
    justify-content: flex-start;
    overflow-x: scroll;
    overflow-y: hidden;
    height: fit-content;
    min-height: 170px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    background: linear-gradient(to bottom, var(--blue-gray), var(--dark-gray), var(--blue-gray));
  }
}