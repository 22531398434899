.trading-point {

  h3 {
    margin: 0;
    margin-left: 15px;
    color: var(--green-500);
    font-size: 32px;
    font-family: "Oswald", sans-serif;
    margin-bottom: 10px;
    line-height: 1.01em;
  }

  .stats {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;


  }
  .search-zone {
    margin-top: 28px;
    margin-left: 12px;
    width: calc(33% - 32px);
    background: transparent;
    input {
      width: 100%;
      background: linear-gradient(90deg, var(--dark-white) 0%, var(--white) 300%);
      color: var(--green-500);
    }
  }

}

@media (max-width: 500px) {
  .trading-point {
    .search-zone {
      width: calc(100% - 16px);

      input {
        width: 100%;
        font-size: 19px;
        background: linear-gradient(90deg, var(--dark-white) 0%, var(--white) 300%);
        color: var(--green-500);
      }
    }
  }
}