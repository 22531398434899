@media (max-width: 500px) {
    .chat-opening-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 30px;
        position: relative;
        width: 44px;
        margin-left: 0px;
    
        .chat-opening-button {
            width: 30px;
            height: 30px;
            font-size: 15px;
            margin-bottom: 3px;

            .amount {
                left: 2px;
                width: 15px;
                height: 15px;
                font-size: 10px;
            }
            
        }
        .title {
            font-size: 8px;
        }
    }
}
