.login-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 25px;
  border-radius: 10px;
  border: 2px solid var(--green-500);
  transition: 400ms;

  h2 {
    margin: 0 ;
    color: var(--light-gray);
    text-align: center;
    font-family: 'Oswald';
    font-size: 56px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 30px;
  }

  button {
    margin-top: 20px;
  }

  p {
    color: var(--green-500);
    text-align: center;
    margin: 0;
    margin-top: 14px;
    cursor: pointer;
  }


}

@media (max-width: 500px) {
  .login-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 10px;
    padding-bottom: 25px;
    border-radius: 10px;
    box-sizing: border-box;
    border: 2px solid var(--green-500);
    width: 80vw;
    background: transparent;

    h2 {
      margin: 0 ;
      color: var(--light-gray);
      text-align: center;
      font-family: 'Oswald';
      font-size: 56px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 30px;
    }

    input {
      font-size: 18px;
    }

    button {
      margin-top: 20px;
    }
  }
}