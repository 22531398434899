.education-page {
  color: var(--white);
  padding: 16px;
  h1 {
    font-family: 'Oswald', sans-serif;
    font-size: 2.4rem;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--white);
  }

  a {
    color: var(--green-500);
    font-weight: bold;
    text-decoration: none;
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      bottom: -2px;
      left: 0;
      background-color: var(--green-500);
      transform: scaleX(0);
      transition: transform 0.3s ease;
    }

    &:hover::after {
      transform: scaleX(1);
    }
  }

  .filtering {
    width: 100%;
    position: sticky;
    margin-bottom: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;

    input {
      background: linear-gradient(90deg, var(--dark-gray), var(--black));
      border: none;
      width: 100%;
      color: var(--green-500);
      font-size: 18px;
    }
    i {
      position: absolute;
      margin: 0;
      right: 10px;
      top: 10px;
      color: var(--white);
      opacity: 0.5;
    }
  }

  .education-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;

    .article-button {
      padding: 16px;
      position: relative;
      width: calc(25% - 32px);
      border-radius: 8px;
      //background-color: var(--blue-gray);
      background-color: var(--dark-gray);
      transition: all 0.2s ease-in-out;
      height: 230px;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
      }

      h2 {
        font-family: 'Oswald', sans-serif;
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: 18px;
        margin-top: 0;
        color: var(--white);
      }

      p.annotation {
        margin: 0;
        margin-top: 8px;
        font-size: 1rem;
        color: var(--gray-500);
        line-height: 1.01;
        max-height: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .learn-more {
        position: absolute;
        bottom: 0;
        margin-top: auto;
        font-size: 1rem;
        color: var(--green-500);
        font-weight: 900;
        cursor: pointer;
      }
      padding-bottom: 50px;
    }

  }


  .knowledge-node-content {
    padding: 16px;
    background-color: var(--blue-gray);
    border-radius: 8px;
    max-width: 800px;
    width: 80vw;
    min-width: 300px;
    max-height: 80vh;
    overflow-y: auto;
    margin: 16px;

    h1 {
      font-family: 'Oswald', sans-serif;
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 16px;
      color: var(--green-500);
    }
    div {
      margin-top: 16px;
    }

  }

}

@import "education-md";
@import "education-xs";