.comments {
  padding: 0 !important;
  border-top: 1px solid var(--light-white-alpha-10);

  .comments-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-family: "Oswald", serif;
      margin-bottom: 20px;
      color: var(--green-500);
    }
    span {
      color: var(--green-500);
      cursor: pointer;
      font-size: 14px;
      opacity: 0.8;
      text-decoration: underline;
      padding: 5px;
    }
  }

  .comments-list {
    .comment-container {
      padding: 0px;
      margin-bottom: 20px;
      p {margin: 0;}
      .comment-author {
        font-size: 12px;
        font-weight: thin;
        margin-bottom: 5px;
        opacity: 0.8;
      }
      .comment-text {
        font-size: 16px;
      }
    }
  }

  .comments-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    width: 100%;  


    textarea {
      width: 100%;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid var(--green-500);
      padding: 0px;
      margin-bottom: 10px;
      color: var(--light-white);
      outline: none;
      font-size: 19px;
      
      &:focus {
        outline: none;

      }
    }

    button {
      span {
        font-size: 12px;
        margin-right: 10px;
        text-transform: uppercase;
        color: var(--light-white);
        opacity: 0.8;
      }
      i {
        color: var(--light-white);
      }
      width: 100%;
      background-color: var(--green-500);
      border: none;
      border-radius: 10px;
    }
    margin-bottom: 20px;
  }
}