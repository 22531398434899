@import "src/styles/animations/skeleton/mixins/skeleton_mixin";
@media (max-width: 500px) {
  .story-square {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 4px;
    border: 1px solid var(--green-500);
    min-width: 90px;
    max-width: 90px;
    min-height: 90px;
    overflow: hidden;
    margin: 0px;
    margin-right: 8px;
    border-radius: 7px;
    cursor: pointer;
    transition: 400ms;

    &:hover {
      box-shadow: 1px 1px 6px var(--green-500);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      filter: brightness(50%);
    }

    p.title {
      text-shadow: var(--black) 1px 1px;
      margin-top: auto;
      margin-bottom: 0;
      color: var(--light-gray);
      line-height: 1.01em;
      width: 100%;
      font-size: 12px;
      /* Текст сторис */

      /* Auto layout */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px;
      isolation: isolate;
      font-weight: 700;
      position: relative;
      z-index: 1;
      opacity: 0.8;
    }

    svg.back-shadow {
      position: absolute;
      top: 40%;
      left: -20%;
      opacity: 1;
      filter: blur(15px);
      z-index: 0;
    }
  }

  .story-square-skeleton {
    @extend .story-square;
    @include skeleton;
  }
}