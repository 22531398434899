@media (max-width: 500px) {
    .chat-vertical-bar {
        flex-direction: row;
        width: 100%;
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 4px;
        position: relative;
        height: 100%;

        input.filter {
            display: none;
        }

        .birthdays-title {
            display: none;
        }
        .birthdays-part {
            display: none;
            flex-direction: row;
            overflow-y: visible;
            overflow-x: scroll;
            width: 100%;
        
            .birthday-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                align-content: center;
                margin: 5px;
                padding-left: 5px;
                padding-right: 5px;
                transform: rotate(90deg);
                border-radius: 10px;
                font-size: 10px;
                color: var(--green-500);
                border: 1px solid var(--green-500);
            }
        }

        .chats-part {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            height: 60%;
            max-height: unset;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        h3 {
            transform: rotate(-90deg);
            font-size: 44px;
            margin-top: auto;
            opacity: 0.8;
            position: absolute;
            left: -3px;
            bottom: 0;
            margin: 0;
            margin-bottom: 90px;
        }

    }
}
