@media (max-width: 500px) {
    .news-page {
        .news-page-content {
            h1 {
                font-family: 'Oswald', sans-serif;
                font-size: 1.4rem;
                font-weight: 600;
                margin-bottom: 16px;
                color: var(--white);
                margin-top: 6px;
                margin-bottom: 16px;
                text-align: left;
            }

            .search-bar {
                position: sticky;
                width: 100%;
                max-width: unset;
                top: 65px;
                padding: 0;
                z-index: 1;

                input {
                    width: 100%;
                    margin: 0;
                }
            }

            .news-list {
                display: flex;
                flex-direction: column;
                color: var(--white);
                gap: 16px;
                width: 100%;

                .news-item {
                    padding-top: 20px;
                    width: 100%;
                }
            }
        }
    }
}
