@import "src/styles/animations/skeleton/mixins/skeleton_mixin";

.menu-button {
  width: calc(100% - 5px);
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0;
  margin-left: 5px;
  padding: 3px;
  padding-top: 10px;  
  padding-bottom: 10px;

  i {
    border: 1px solid var(--green-500);
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    border-radius: 50%;
    margin-right: 14px;
    color: var(--green-500);
  }

  .title {
    color: var(--gray-300);
    margin: 0;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: auto;
  }

  .amount {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: auto;
    font-size: 17px;
    //color: var(--green-500);
    color: var(--black);
    font-weight: 900;
    margin-right: 10px;
    font-family: "Oswald", sans-serif;
  }

  .amount-closed {
    @extend .amount;
    font-size: 12px;
    margin-right: 10px;
  }
  &:hover {
    background: var(--green-500);
    border-radius: 30px 30px 30px 30px;

    .title {
      font-weight: 900;
    }
    .amount {
      color: var(--darker-gray);
    }
    i {
      color:  var(--darker-gray);
    }
  }

  .title-skeleton {
    @extend .title;
    height: 30px;
    @include skeleton;
  }
  .amount-skeleton {
    @extend .amount;
    @include skeleton;
  }
}

@import "menu-button-xs";