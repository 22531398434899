body {
  background: -webkit-linear-gradient(45deg, var(--dark-black), var(--blue-gray));
  background: -moz-linear-gradient(45deg, var(--dark-black), var(--blue-gray)); 
  background: -o-linear-gradient(45deg, var(--dark-black), var(--blue-gray));
  background: linear-gradient(45deg, var(--dark-black), var(--blue-gray));
  background-color: var(--blue-gray);

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
