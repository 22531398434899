.news {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  max-width: 99%;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-right: auto;
  padding-left: 16px;
  margin-top: 0;
}

@import "news-xs";