.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    height: 90%;
    width: 100%;
    overflow: hidden;
    border-radius: 5px;

    input.search {
        margin-top: 10px;
        min-height: 30px;
        background-color: green;
    }

    h4.dialog-title {
        color: rgba(255, 255, 255, 0.7);
        background: linear-gradient(45deg, var(--gray-900), var(--gray-700));
        font-size: 20px;
        font-weight: 600;
        width: 100%;
        padding: 10px 10px;
        margin: 0px;
        font-size: 14px;
        border-radius: 5px;
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;

        i {
            margin-left: auto;
            opacity: 0.6;
        }
    }

    .message-input-container {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        width: 100%;

        input {
            width: 100%;
            border-radius: 10px;
            font-size: 1rem;
            font-weight: 600;
            margin: 0;
            color: var(--black);
            border: none;

            &:focus {
                outline: none;
            }

            &::placeholder {
                opacity: 0.5;
                font-size: 10px;
            }
        }

        i {
            opacity: 0.3;
            pointer-events: none;
            position: absolute;
            right: 0px;
            font-size: 1rem;
            background: var(--light-gray);
            border-radius: 50%;
            padding: 10px;
            border-radius: 10px;
            cursor: pointer;
            color: var(--green-500);

            &.active {
                opacity: 1;
                pointer-events: auto;
            }
        }

    }

    .messages-container {
        background: rgba(0, 0, 0, 0.6);
        padding: 10px;
        display: flex;
        flex-direction: column;
        border-radius: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 100%;
        width: 100%;
        margin: 0;

        .loading {
            font-size: 0.7rem;
            font-weight: 600;
            color: var(--green-500);
        }

        .no-messages {
            font-size: 0.7rem;
            font-weight: 600;
            color: var(--white);
        }
    }
}