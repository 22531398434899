@media (max-width: 768px) {
    .small-main-metric-widget {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-content: stretch;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 2px;
        padding-left: 0px;
        padding-right: 0px;
        border-radius: 8px;
        margin-right: 0px;
        // background-color: var(--blue-gray);
        color: var(--light-gray);
        // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease-in-out;
        svg {
            width: 300px;
        }
        &:hover {
            transform: unset;
        }
        h1 {
            font-size: 14px;
            font-weight: 400;
            font-family: 'Oswald', sans-serif;
            margin: 0;
            margin-right: 16px;
            opacity: 0.7;
            text-align: center;
            width: 100%;
        }
    
        h2 {
            position: absolute;
            font-size: 18px;
            width: 100%;
            text-align: center;
            opacity: 0.7;
            font-weight: 400;
            font-family: 'Oswald', sans-serif;
            margin: 0;
        }
    }
}