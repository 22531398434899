button {
  display: flex;
  padding: 14px 20px;
  justify-content: center;
  font-weight: 700;
  align-items: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  &.green {
    background: var(--green-500);
    background-color: var(--green-500);
    color: var(--white);
  }
}