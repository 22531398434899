@media (max-width: 500px) {
  main {
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 100%;
    max-width: 100vw;
    padding-bottom: 100px;

    .hide-xs {
      display: none;
    }
  }
}
