.backdrop {
  display: none;
}
@media (max-width: 500px) {
  .backdrop {
    transition: 4000ms;
    animation-delay: 300ms;
    &-open {
      display: flex;
      position: fixed;
      z-index: 15;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--black-alpha80);
    }
  }
}
