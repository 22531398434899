.trading-points-page-content {
    padding: 16px;
    width: 100%;
    
    h1 {
        margin-bottom: 16px;
        color: var(--white);
    }

    .partners-list {
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
        width: 100%;
        overflow-x: scroll;
        color: var(--green-500);
        input {
            background: transparent;
            border: 1px solid var(--green-500);
            margin: 0;
            color: var(--green-500);
        }
        h3 {
            margin: 0;
        }
        p {
            white-space: nowrap;
            margin: 0;
            padding: 10px;
            border-radius: 10px;
            border: 1px solid var(--green-500);
            color: var(--green-500);
            cursor: pointer;
            &:hover {
                color: var(--black);
                background: var(--green-500-alpha14);
            }
        }

        .active-partner {
            background-color: var(--green-500);
            color: var(--black);
            font-weight: 600;
        }

        margin-bottom: 10px;
    }
    .trading-points-list-container {
        min-width: 300px;
        display: flex;
        flex-direction: row;
        width: 100%;



    }
}

@import "trading-points-page-md";
@import "trading-points-page-xs";