@media (max-width: 500px) {
  .education-page {
    color: var(--white);
    padding: 16px;

    h1 {
      font-family: 'Oswald', sans-serif;
      font-size: 2.4rem;
      font-weight: 600;
      margin-bottom: 16px;
      color: var(--white);
    }


    .filtering {
      width: 100%;
      margin-bottom: 18px;
      display: flex;
      flex-direction: row;
      align-items: center;
      align-content: center;
      top: 65px;
      position: sticky;

      input {
        background: linear-gradient(90deg, var(--dark-gray), var(--black));
        border: none;
        width: 100%;
        color: var(--green-500);
        font-size: 18px;
      }

      i {
        position: absolute;
        margin: 0;
        right: 10px;
        top: 10px;
        color: var(--white);
        opacity: 0.5;
      }
    }

    .education-buttons {

      .article-button {
        width: 100%;
        padding: 16px;
        margin-bottom: 16px;
        border-radius: 8px;
        transition: all 0.2s ease-in-out;
        height: unset;

        &:hover {
          transform: translateY(-2px);
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }

        h2 {
          font-family: 'Oswald', sans-serif;
          font-size: 1.6rem;
          font-weight: 500;
          margin-bottom: 18px;
          margin-top: 0;
          color: var(--white);
        }

        p.annotation {
          margin: 0;
          margin-top: 8px;
          font-size: 1rem;
          line-height: 1.4;
          color: var(--gray-500);
          line-height: 1.01;
          height: unset;
          max-height: unset;
        }

        .learn-more {
          margin-top: 16px;
          font-size: 1rem;
          color: var(--green-500);
          font-weight: 900;
          cursor: pointer;
          position: relative;
          bottom: unset;
        }
      }
    }

    .knowledge-node-content {
      padding: 16px;
      background-color: var(--blue-gray);
      border-radius: 8px;
      max-width: 800px;
      width: 80vw;
      min-width: 300px;
      max-height: 80vh;
      overflow-y: auto;
      margin: 16px;

      h1 {
        font-family: 'Oswald', sans-serif;
        font-size: 2.4rem;
        font-weight: 600;
        margin-bottom: 16px;
        color: var(--green-500);
      }

      div {
        margin-top: 16px;
      }

    }

  }
}