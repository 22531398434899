@media (max-width: 1600px) {
  .documents-page {
    padding: 10px;
    width: 100%;

    .documents {
      .document-item {
        width: calc(50% - 32px);
        .document-item-content {
        }
      }
    }
  }
}