@media (max-width: 1600px) {
  .trading-points-list-container {
    .trading-point-chooser {
      .search-bar {
        input {
          &::placeholder {
            color: var(--gray-500);
          }
        }
      }

      .trading-points-list {
        padding: 0px;
        .trading-point-button {
          padding: 3px;
          padding-bottom: 0;
          padding-top: 12px;
          .metrics {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            border-bottom: 1px solid var(--light-blue-gray);
            padding-bottom: 10px;

            h5 {
              font-family: 'Oswald', sans-serif;
              font-weight: 600;
              font-size: 16px;
              margin: 0;
              margin-right: 7px;
              margin-bottom: 5px;
            }

            p.target-plan {
              min-width: 100px;
              text-align: center;
              color: var(--green-500);
              font-family: 'Oswald', sans-serif;
              font-size: 18px;
              background-color: rgba(0, 0, 0, 0.5);
              padding: 6px;
              border-radius: 9px;
              margin: 2px;
              margin-left: 6px;
              display: flex;
              flex-direction: row;
              align-items: center;
              align-content: center;
              justify-content: space-between;

              i {
                font-size: 23px;
                margin: 0;
                margin-right: 6px;
              }
            }
          }

          &:hover {
            background-color: var(--green-500);
          }
        }
      }
    }
  }
}