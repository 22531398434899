@import "src/styles/animations/skeleton/mixins/skeleton_mixin";
.story-square {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  border: 1px solid var(--green-500);
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  overflow: hidden;
  margin: 8px;
  margin-right: 16px;
  border-radius: 7px;
  cursor: pointer;
  transition: 400ms;
  &:hover {
    box-shadow: 1px 1px 6px var(--green-500);
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    filter: brightness(80%) blur(0.3px);
  }

  p.title {
    backdrop-filter: blur(1px);
    background: linear-gradient(180deg, transparent 0%, var(--black-alpha80) 60%);
    text-shadow: var(--black) 1px 1px;
    margin: 0;
    margin-top: auto;
    margin-bottom: 0;
    color: var(--light-white);
    line-height: 1.01em;
    width: 100%;
    font-size: 12px;
    /* Текст сторис */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    isolation: isolate;
    font-weight: 700;
    position: relative;
    z-index: 1;
    text-shadow: var(--black) 1px 1px;
  }

  svg.back-shadow {
    position: absolute;
    top: 70%;
    fill: var(--black-alpha80);
    filter: blur(15px);
  }
}

.story-square-skeleton {
  @extend .story-square;
  @include skeleton;
}

@import "story-square-xs";