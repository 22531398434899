@media (max-width: 500px) {
  .search {
    width: calc(100%);
    margin: 0;
    margin-right: 25px;
    margin-left: 5px;
    .search-container {
      width: 100%;
      margin: 0;

      i.fa-search {
        position: absolute;
        left: unset;
        right: 10px;
      }

      input.search-input {
        width: 100%;
        padding-left: 10px;
        font-size: 16px;
      }

      button {
        display: none;
      }
    }
  }
}