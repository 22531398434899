@media (max-width: 500px) {

  .news-article {
    border-radius: 10px;
    background: var(--blue-gray);
    padding: 0px;
    min-width: 220px;
    min-height: 220px;
    display: flex;
    flex-direction: column;
    //border: 2px solid var(--green-500);
    width: 90vw;
    overflow-y: scroll;
    max-height: 80vh;

    h1 {
      font-family: "Oswald";
      margin: 0;
      margin-bottom: 0px;
      color: var(--green-500);
      font-size: 24px;
    }
    h2 {
      margin: 0;
      font-size: 20px;
      color: var(--darker-gray);
      margin-bottom: 5px;
    }
  }
}
