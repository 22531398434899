@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,100..700;1,100..700&family=Oswald:wght@100..700&display=swap');

@import "colors/colors";
@import "shadows/shadows";
@import "layout/application";
@import "scrollbars/scrollbar";
@import "inputs/inputs";

* {
  box-sizing: border-box;
  font-family: 'Arimo', sans-serif;
  font-style: normal;
}

h1 {
  font-family: 'Oswald', sans-serif;
  font-size: 2.4rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
  color: var(--white);
}