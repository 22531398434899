@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,100..700;1,100..700&family=Oswald:wght@100..700&display=swap');
@import "/src/styles/animations/skeleton/mixins/skeleton_mixin";

.main-metric-widget {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  border-radius: 10px;
  background: var(--blue-gray);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
  //min-width: 120px;
  width: calc(33% - 29px);
  //max-width: 330px;
  margin: 10px;
  overflow: hidden;
  height: 160px;

  .content {
    width: 50%;

    .value {
      margin: 10px;
      color: var(--light-gray);
      font-family: 'Oswald';
      font-size: 36px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .caption {
      opacity: 0.7;
      margin: 10px;
      color: var(--light-gray);
      font-family: 'Arimo';
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .prev-delta {
      margin: 10px;
      margin-top: 20px;
      color: var(--green-500);
    }
  }

  .full-screen-view-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background-color: var(--dark-white);
    padding: 20px;
    border-radius: 10px;
    max-width: 90vw;
    max-height: 90vh;
    min-width: 50vw;

    


    h1 {
      color: var(--green-500);
      margin: 0;
    }

    h2 {
      margin: 0;
      margin-bottom: 20px;
      color: var(--gray);
    }

    .line-chart {
      background: transparent;
    }
  }

  .chart-loading-skeleton {
    display: flex;
    height: 120px;
    width: 200px;
    border-radius: 10px;
    margin: 10px;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    @include  skeleton;

    i {
      opacity: 0.05;
    }
  }
}

@import "main_metric_widget_md";
@import "main_metric_widget_xs";
