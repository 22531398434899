@media (max-width: 1900px) {
    .inspections-filters {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 30px;
        align-items: center;
        align-content: center;
        overflow-x: auto;
        p {
            margin: 0;
            padding: 0;
            font-size: 14px;
            color: var(--gray-500);
            white-space: wrap;
            margin-right: 20px;
        }
        button {
            height: 100%;
            color: var(--white);
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 5px;
            padding-bottom: 5px;
            border-radius: 10px;
            background-color: var(--black);
            &.active {
                background-color: var(--green-500);
            }
            min-width: 190px;
            font-size: 10px;
        }
    }
    
    .inspections {
        display: flex;
        width: 100%;
        margin-left: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        color: var(--white);
        gap: 30px;
        h1 {
            font-size: 24px;
        }
    
        .inspection-item {
            width: calc(100% - 30px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            align-content: center;
            background: linear-gradient(40deg, var(--dark-gray), var(--blue-gray));
            padding-left: 10px;
            padding-right: 10px;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 10px 10px 10px 10px;
            position: relative;
    
            &__info {
                margin: 0;
                display: flex;
                flex-direction: column;
                height: 100%;
                &__dates {
                    margin-top: 30px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 30px;
                    width: 100%;
                    font-size: 14px;
                    color: var(--gray-500);
        
                    i {
                        font-size: 18px;
                        color: var(--gray-500);
                        opacity: 0.5;
                    }
        
                    p {
                        margin: 0;
                        padding: 0;
                    }
        
                    &__started-at {
                        font-size: 18px;
                        color: #fff;
                        opacity: 0.5;
                    }
            
                    &__finished-at {
                        font-size: 18px;
                        color: #fff;
                        opacity: 0.5;
                    }
                }
    
                &__pattern {
                    font-size: 18px;
                    background: var(--green-500);
                    color: #ededed;
                    opacity: 0.8;
                    background-color: inherit;
                    text-transform: uppercase;
                    border-bottom: none;
                    width: 100%;
                    margin-bottom: 10px;
                    box-sizing: border-box;
                    border-radius: 10px 10px 0px 0px;
                    text-align: left;
                    margin-top: 0px;
                    font-weight: bold;
                }
    
                &__company {
                    padding: 0;
                    margin: 0;
                    margin-top: 18px;
                    font-size: 14px;
                    color: var(--green-500);
                    font-weight: thin;
                    line-height: 1.0;
                    margin-bottom: auto;
                }
            }
    
        }
    
    
    }
}