@import "src/styles/animations/skeleton/mixins/skeleton_mixin";

.message-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
    padding-top: 7px;
    padding-bottom: 1px;
    background: var(--blue-gray);
    width: 80%;
    color: var(--white);
    margin-bottom: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

    .read-status {
        opacity: 0.5;
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    button {
        background: var(--blue-gray);
        width: 100%;
        color: var(--white);
        border: none;
        padding: 1px;
        border-radius: 5px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid var(--white);

        &:hover {
            background: var(--white);
            color: var(--blue-gray);
        }
    }
    .first-line {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        .author {
            font-weight: bold;
            margin: 0px;
            margin-top: 3px;
            font-size: 10px;
        }
        .created-at {
            text-align: right;
            font-size: 8px;
            margin: 0px;
            margin-top: 3px;
        }
    }

    .second-line {  
        .content {
            margin-top: 8px;
            padding: 1px;
        }
    }
}

.message-container-skeleton {
    @extend .message-container;
    @include skeleton;
    min-height: 70px;
}

