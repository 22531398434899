.line-chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: var(--blue-gray);
    padding: 10px;
    border-radius: 3px;
    height: 100%;
    position: relative;

    .legend {
        display: flex;
        flex-direction: column;
        background: var(--white);
        padding: 10px;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
        opacity: 0.7;
        gap: 5px;

        .label-legend {
            .pseudo-line {
                display: flex;
                width: 20px;
                height: 3px;
                margin-right: 8px;

            }
            color: var(--black);
            display: flex;
            flex-direction: row;
            align-items: center;
            align-content: center;
            justify-content: space-between;
            font-size: 10px;
        }
    }
}