.modal-window {
  position: fixed;
  z-index: 100;
  background: var(--light-white-alpha-10);

  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  backdrop-filter: blur(5px);
  transition: 400ms;
  pointer-events: none;
  opacity: 0;

  .modal-shadow {
    box-shadow: 0 4px 15px var(--green-500-alpha14),
                0 4px 5px var(--green-500-alpha50),
                0 0 20px var(--light-white-alpha-40);
    background: linear-gradient(45deg, var(--green-500-alpha14), var(--light-white-alpha-10));
    background: -webkit-linear-gradient(45deg, var(--green-500-alpha14), var(--light-white-alpha-10));
    background: -moz-linear-gradient(45deg, var(--green-500-alpha14), var(--light-white-alpha-10));
  }

  .full-metric-widget {
    max-width: 100%;
  }
}