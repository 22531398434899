.small-main-metric-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 2px;
    padding-left: 0px;
    padding-right: 0px;
    transform: scale(0.9);
    // background-color: var(--blue-gray);
    color: var(--light-gray);
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    svg {
        width: 100%;
    }
    &:hover {
        transform: scale(1.3);
    }
    h1 {
        font-size: 22px;
        font-weight: 400;
        font-family: 'Oswald', sans-serif;
        margin: 0;
        margin-right: 16px;
        opacity: 0.7;
        text-align: center;
        width: 100%;
    }

    h2 {
        position: absolute;
        font-size: 18px;
        width: 100%;
        text-align: center;
        opacity: 0.7;
        font-weight: 400;
        font-family: 'Oswald', sans-serif;
        margin: 0;
    }

}
.widget-view-content {
    background: linear-gradient(40deg, var(--dark-gray), var(--blue-gray));
    padding: 10px;
    border-radius: 10px;
    color: var(--light-gray);
    width: 70%;
    max-width: 800px;
    @media (max-width: 768px) {
        width: 100%;
    }
}

@import "./small-main-metric-widget-xs.scss";