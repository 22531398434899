aside {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
  box-sizing: border-box;
  z-index: 5;
  position: relative;
  min-width: 223px;
  height: 100vh;
  overflow-y: scroll;
  background: var(--dark-gray);
  box-shadow: var(--shadow-1);
  color: var(--white);
  transition: 600ms ease-in-out;
  padding-bottom: 0px;
  overflow-x: hidden;

  .backdrop {
    display: none;
    opacity: 0;
    pointer-events: none;
  }

  hr {
    margin: 0;
    border-radius: 5px;
    border-top: 1px solid var(--light-white-alpha-40);
    border-bottom: 1px solid var(--light-white-alpha-40);
    border-right: 1px solid var(--light-white-alpha-40);
    border-left: 1px solid var(--light-white-alpha-40);
  }
}

@import "side-menu-xs";