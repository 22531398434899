@media (max-width: 500px) {
.user-small-widget {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 4px;
  min-height: unset;
  max-width: 45px;
  padding: 0px 0px;
  .initials {
    width: 30px;
    height: 30px;
    font-size: 10px;
    padding: 10px;
    margin: 0;
    margin-right: 10px;
  }

  p.name {
    font-family: Arimo;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
  }
}

}
