.user-small-widget {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  .initials {
    background: var(--blue-gray);
    border: 1.6px solid var(--background);
    outline: 3px solid  var(--green-500);
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 12px;
    padding: 10px;
    margin-right: auto;
    font-family: "Oswald", sans-serif;
    margin-right: 10px;
  }

  p.name {
    font-family: "Oswald", sans-serif;
    font-size: 19px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

@import "./user-small-widget-xs";