@media (max-width: 500px) {
  header {
    //position: relative;
    //z-index: 40000000;
    .xs-top {
      position: fixed;
      top: 0;
      z-index: 10;
      left: 0;
      width: 100%;
      //z-index: 11;
      background: var(--dark-gray);
      padding: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      display: flex;
      color: var(--light-gray);
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
    }

    .news {
      margin-top: 45px;
    }
  }
}
