.dialogs-page-content {
    padding: 20px;
    padding-top: 0;
    color: var(--white);
    font-family: "Oswald", sans-serif;
    h1 {
        color: var(--white);
    }

    .chat-wrapper {
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        align-content: flex-start;
        height: 50vh;
        justify-content: center;
        background: linear-gradient(135deg, rgba(6, 64, 71, 0.85), rgba(15,15,15,0.95), rgba(15,15,15,0.95), rgba(15,15,15,0.95), rgba(0,0,0,0.98)),
                    radial-gradient(circle at center, rgba(255,255,255,0.15), transparent 60%);
        padding: 0;
        overflow: hidden;

        input {
            width: 100%;
            padding: 10px;
            box-sizing: border-box;
            margin-bottom: 30px;
            margin-top: 10px;
            background: transparent;
            border: 2px solid var(--gray-300);
            opacity: 0.6;
            color: var(--white);
            
            &::placeholder {
                color: var(--white);
            }
        }

        .chat-buttons-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            backdrop-filter: blur(10px);
            height: 100%;
            min-height: 50vh;
            max-height: 50vh;
            overflow-y: auto;
            padding: 10px;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
            box-sizing: border-box;
            width: 300px;
            position: relative;
            z-index: 1;
            margin-right: 10px;

            .loading-chats {
                width: 100%;
                text-align: center; 
                font-size: 10px;
                font-weight: 600;
            }

            .chat-button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--dark-gray);
                color: var(--white);
                padding: 10px;
                cursor: pointer;
                margin-bottom: 1px;
        
                p {
                    margin: 0;
                }
        
                &:hover {
                    background-color: var(--green-500);
                    color: var(--black);
                }

                &--active {
                    background: linear-gradient(45deg, var(--green-500), var(--green-700), var(--black));
                    box-shadow: 0 2px 12px rgba(0, 255, 0, 0.15);
                    border: 1px solid rgba(255, 255, 255, 0.1);
                    font-weight: 900;
                    color: rgba(255, 255, 255, 0.7);
                }
            }

        }

        .chat-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .chat-container-empty-state {
                font-size: 40px;
                opacity: 0.5;
            }
        }
        
    }
}


@media (max-width: 768px) {
    .dialogs-page-content {
        background-color: transparent;

        .chat-wrapper {
            background: transparent;
        }

        .chat-wrapper {

            background-color: transparent;
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 5px;
            
            .chat-buttons-container {
                min-height: unset;
                width: 100%;
                margin-bottom: 10px;
            }


            .chat-container {
                width: 100%;
                max-height: unset;
                height: 100%;
                background-color: transparent;

                .chat-container-empty-state {
                    display: none;
                }
            }
        }

    }
}
