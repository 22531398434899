@mixin skeleton {
  overflow: hidden;
  position: relative;
  border: none;
  background: var(--dark-gray);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
                    90deg,
                    transparent 0,
                    rgba(#fff, 0.2) 20%,
                    rgba(#fff, 0.5) 60%,
                    transparent
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}