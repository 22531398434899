main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  padding: 10px;
  width: 100%;
  height: 100%;
  max-width: 99vw;
  overflow-y: scroll;
  overflow-x: hidden;
}

@import "main-xs";